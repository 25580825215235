import React from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';

const OfficeInfo = ({ city, address }) => {
  return (
    <Grid templateColumns="12px auto" templateRows="36px auto" gap="12px">
      <Box borderRadius="full" placeSelf="center" boxSize="12px" bgColor="bg.accent"></Box>
      <Text color="text.white">{city}</Text>
      <Text
        textStyle="h3"
        gridColumn={2}
        gridRow={2}
        color="#A7A9A5"
        dangerouslySetInnerHTML={{ __html: address }}
      ></Text>
    </Grid>
  );
};

export default OfficeInfo;
