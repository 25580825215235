import { extendBaseTheme } from '@chakra-ui/react';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';
import {
  colors,
  breakpoints,
  Checkbox,
  Button,
  textStyles,
  styles,
  Modal,
  Input,
  Textarea,
  Tabs,
} from './themeConfig';

const theme = {
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  components: {
    Button,
    Modal,
    Checkbox,
    Input,
    Textarea,
    Tabs,
  },
  styles,
  colors,
  breakpoints,
  textStyles,
};

export default extendBaseTheme(theme);
