const Button = {
  baseStyle: {
    borderRadius: '100px',
    border: '1.6px solid',
    color: 'text.black',
    borderColor: 'text.black',
    boxSize: 'fit-content',
    fontWeight: 500,
    bgColor: 'transparent',
    m: 0,
    transition: 'color 0.2s, background-color 0.2s',
    _hover: {
      bgColor: 'bg.black',
      color: 'text.white',
      img: {
        filter: 'brightness(0) invert(1)',
      },
    },
    _disabled: {
      opacity: 0.3,
      _hover: {
        cursor: 'default',
        color: 'text.black',
        bgColor: 'transparent',
      },
    },
  },
  sizes: {
    primary: {
      p: { laptop: '32px 44px', mobile: '20px 32px' },
      textStyle: 'button.primary',
    },
    secondary: {
      p: { laptop: '20px 28px', mobile: '12px 18px' },
      textStyle: 'button.secondary',
    },
  },
  variants: {
    light: {
      borderColor: 'text.white',
      color: 'text.white',
      _hover: {
        bgColor: 'bg.white',
        color: 'text.black',
      },
    },
    default: {
      _hover: {
        bg: 'none',
        color: 'bg.black',
        img: {
          filter: 'none',
        },
      },
      p: 0,
      m: 0,
      border: 'none',
    },
    dropdown: {
      justifyContent: 'space-between',
      textAlign: 'start',
    },
    disableIconBtn: {
      bgColor: 'transparent',
      filter: 'brightness(0) invert(1)',
      border: '1px solid',
      borderColor: 'bg.white',
    },
  },
  defaultProps: {
    size: 'primary',
    variant: '',
  },
};

export default Button;
