import React from 'react';

import {
  AccordionButton,
  Text,
  AccordionItem,
  AccordionPanel,
  HStack,
  Image,
  Link,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

const AccordionInfo = ({
  title,
  content,
  panelStyle = null,
  containerStyle = null,
  leftIcon = null,
  rightIcon = null,
  link = null,
}) => {
  return (
    <AccordionItem w="full" sx={containerStyle}>
      {({ isExpanded }) => (
        <>
          <AccordionButton w="full" flex={1} justifyContent="space-between" gap="12px">
            <HStack spacing="12px">
              {leftIcon}
              <Text align="start" textStyle="h1">
                {title}
              </Text>
            </HStack>
            <Image
              src={rightIcon}
              transform={isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'}
              transition="transform .2s"
            />
          </AccordionButton>
          <AccordionPanel sx={panelStyle} motionProps={{ unmountOnExit: true }}>
            <Text dangerouslySetInnerHTML={{ __html: content }} mb={link && '20px'} />
            {link && (
              <Link
                as={GatsbyLink}
                to={link}
                display="block"
                mt="20px"
                textStyle="button.secondary"
                textDecoration="underline"
                textUnderlineOffset="3px"
                mb="1px"
                textAlign="end"
              >
                Подробнее
              </Link>
            )}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
export default AccordionInfo;
