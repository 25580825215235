import React, { forwardRef, useEffect, useRef, memo } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Flex, Link as ChakraLink } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Logo from 'shared/Logo';
import MenuToggle from 'shared/MenuToggle';
import MobileNavigation from '~entities/MobileNavigation';
import Navigation from '~entities/Navigation';
import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';

const Header = (props, ref) => {
  const { isMenuOpen, isAnimated, isLight, setIsAnimated } = useBoundStore(headerSelector);

  const raf = useRef(null);
  useEffect(() => {
    function spyHeader() {
      if (window.scrollY >= 20) {
        setIsAnimated(true);
      } else {
        setIsAnimated(false);
      }
      raf.current = requestAnimationFrame(spyHeader);
    }
    raf.current = requestAnimationFrame(spyHeader);
    return () => cancelAnimationFrame(raf.current);
  }, []);
  return (
    <Flex
      as={motion.header}
      initial={false}
      animate={isMenuOpen ? 'open' : 'closed'}
      ref={ref}
      pos="fixed"
      top={0}
      left={0}
      bgColor={isAnimated ? 'rgba(255, 255, 255, 0.40)' : 'transparent'}
      backdropFilter="blur(20px)"
      zIndex={1200}
      justifyContent="space-between"
      alignItems="center"
      boxSizing="border-box"
      borderStyle="solid"
      transition="border-width .1s linear, background-color .2s linear"
      borderBottomWidth={isAnimated ? '1.6px' : '0px'}
      borderColor={`text.${isLight ? 'white' : 'black'}`}
      p={{ laptop: '20px 40px', tablet: '20px 24px', mobile: '0px 16px' }}
      w="100vw"
    >
      <ChakraLink as={GatsbyLink} to="/">
        <Logo light={isLight} w={{ tablet: '164px', mobile: '123px' }} />
      </ChakraLink>
      <Navigation />
      <MenuToggle
        hideFrom="md"
        h="32px"
        w="42px"
        zIndex={1100}
        mt={{ mobile: '10px', tablet: '0px' }}
      />
      <MobileNavigation
        gap="40px"
        zIndex={1050}
        pos="fixed"
        h="100vh"
        right={0}
        top={0}
        p="90px 20px 0 20px"
        bgColor="bg.white"
      />
    </Flex>
  );
};

export default memo(forwardRef(Header));
