import { Button, HStack } from '@chakra-ui/react';
import React, { memo } from 'react';
import { goToForm } from 'shared/lib/goToForm';
import { links } from 'constants/header';
import NavItem from 'shared/NavItem';
import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';

const Navigation = () => {
  const { isLight } = useBoundStore(headerSelector);
  return (
    <HStack
      as="nav"
      gap="40px"
      transition="color .2s linear"
      hideBelow="md"
      color={`text.${!isLight ? 'black' : 'white'}`}
    >
      {links?.map((link, i) => (
        <NavItem key={i} {...link} />
      ))}
      <Button size="secondary" onClick={goToForm} variant={isLight && 'light'}>
        Contact us
      </Button>
    </HStack>
  );
};
export default memo(Navigation);
