import inst_green from 'assets/People/Icons/inst_green.png';
import youtube_green from 'assets/People/Icons/youtube_green.svg';
import whatsApp_green from 'assets/People/Icons/whatsApp_green.png';

export const socialMedias = [
  {
    href: '',
    src: whatsApp_green,
    alt: 'whatsapp',
  },
  {
    href: 'https://www.instagram.com/aston.it/',
    src: inst_green,
    alt: 'inst',
    isBlocked: true,
  },
  {
    href: 'https://www.youtube.com/@astonlive2023',
    src: youtube_green,
    alt: 'youtube',
  },
];

export const offices = [
  {
    city: 'Dubai',
    address: `JAFZA One Tower A, 11th Floor,<br/>Dubai, UAE`,
  },
  {
    city: 'Limassol',
    address: '377, 28th October Street, 4th floor, SOBOH HOUSE, Neapolis, 3107<br/>Cyprus',
  },
  {
    city: 'Almaty',
    address: 'Koktem-2 Microdistrict, building 22 Bostandyq District<br/>Kazakhstan',
  },
  {
    city: 'Moscow',
    address: `BC Imperia Tower, floor 50, office 13 Presnenskaya Embankment 6, building 2<br/>Russia`,
  },
  {
    city: 'Saint Petersburg',
    address: 'BC Renaissance Hall, floor 8, 801 Vladimirsky Avenue 23<br/>Russia',
  },
  {
    city: 'Minsk',
    address: 'Pobediteley Avenue 7А, floor 24<br/>Belarus',
  },
];
