const textStyles = {
  header: {
    h1: {
      fontSize: { laptop: '88px', tablet: '48px', mobile: '40px' },
      lineHeight: '110%',
      fontWeight: 500,
      letterSpacing: '-0.04em',
    },
    h2: {
      fontSize: { laptop: '64px', tablet: '36px', mobile: '28px' },
      lineHeight: '120%',
      fontWeight: 500,
      letterSpacing: '-0.03em',
    },
    h3: {
      fontSize: { desktop: '48px', laptop: '32px', mobile: '24px' },
      fontWeight: 500,
      lineHeight: '120%',
      letterSpacing: '-0.03em',
    },
    h4: {
      fontSize: { desktop: '32px', laptop: '24px', mobile: '18px' },
      lineHeight: '120%',
      fontWeight: 500,
      letterSpacing: '-0.03em',
    },
  },
  btnTag: {
    fontSize: { laptop: '24px', mobile: '14px' },
    lineHeight: { laptop: '120%', mobile: '116%' },
    letterSpacing: { laptop: '-0.48px', mobile: '-0.28px' },
  },
  tag: {
    fontSize: { laptop: '18px', mobile: '12px' },
    lineHeight: '150%',
    letterSpacing: '-0.02em',
  },
  additionalTag: {
    fontSize: '12px',
    lineHeight: '140%',
    fontWeight: 400,
  },
  button: {
    primary: {
      fontSize: { laptop: '24px', mobile: '18px' },
      fontWeight: 500,
      letterSpacing: '-0.02em',
      lineHeight: { laptop: '120%', mobile: '116%' },
    },
    secondary: {
      fontSize: { laptop: '20px', mobile: '14px' },
      fontWeight: 500,
      letterSpacing: '-0.02em',
      lineHeight: { laptop: '120%', mobile: '116%' },
    },
    header: {
      fontSize: { desktop: '20px', laptop: '18px', mobile: '14px' },
      fontWeight: 500,
      letterSpacing: '-0.02em',
      lineHeight: { laptop: '120%', mobile: '116%' },
    },
  },
  h1: {
    fontSize: { desktop: '24px', laptop: '20px', mobile: '16px' },
    fontWeight: { laptop: 500, mobile: 400 },
    letterSpacing: '0px',
    lineHeight: '140%',
  },
  h2: {
    fontSize: { laptop: '18px', mobile: '12px' },
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: '140%',
  },
  h3: {
    fontSize: { laptop: '16px', mobile: '12px' },
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: '140%',
  },
  h4: {
    fontSize: { laptop: '24px' },
    fontWeight: 500,
    lineHeight: '140%',
  },
  radio: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '170%',
  },
};

export default textStyles;
