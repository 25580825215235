import { Button, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { goToForm } from 'shared/lib/goToForm';
import { links, variants } from 'constants/header';
import { navItem } from 'constants/header/variantNavItem';
import NavItemMobile from 'shared/NavItemMobile';
import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';

const MobileNavigation = props => {
  const { toggleMenuOpen } = useBoundStore(headerSelector);
  const handleButton = () => {
    goToForm();
    toggleMenuOpen();
  };
  return (
    <VStack as={motion.nav} variants={variants} {...props}>
      {links?.map((link, i) => {
        return <NavItemMobile key={i} toggle={toggleMenuOpen} {...link} />;
      })}
      <Button as={motion.button} variants={navItem} size="secondary" onClick={handleButton}>
        Contact us
      </Button>
    </VStack>
  );
};
export default MobileNavigation;
