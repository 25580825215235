import { VStack } from '@chakra-ui/react';
import React, { memo } from 'react';

const Wrapper = ({ children, ...props }) => {
  return (
    <VStack
      pos="relative"
      zIndex={10}
      w="100%"
      maxW={{ desktop: '1440px', mobile: 'full' }}
      boxSizing="border-box"
      overflowX="hidden"
      px={{ laptop: '40px', tablet: '20px', mobile: '16px' }}
      {...props}
    >
      {children}
    </VStack>
  );
};
export default memo(Wrapper);
