export const loadedScriptsOnLoad = 'loadedScriptsOnLoad';
export const loadedScriptsOnScroll = 'loadedScriptsOnScroll';

export const recaptchSrc =
  'https://www.google.com/recaptcha/api.js?render=6LdyHYgiAAAAAMhEWylinM6Abzw7tokplKkypSIX';

export const leadFeeder = `(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('lYNOR8xopb5aWQJZ');`;

export const yandexMetrika = `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(90274501, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });`;

export const mailMetrika = `var _tmr = window._tmr || (window._tmr = []);
_tmr.push({id: "3474315", type: "pageView", start: (new Date()).getTime()});
(function (d, w, id) {
  if (d.getElementById(id)) return;
  var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
  ts.src = "https://top-fwz1.mail.ru/js/code.js";
  var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
  if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
})(document, window, "tmr-code");`;

export const mailMetrikaNoscript = {
  styles: {
    position: 'absolute',
    left: '-9999px',
  },
  alt: 'Top.Mail.Ru',
  src: `https://top-fwz1.mail.ru/counter?id=3474315;js=na`,
};

export const metaMetrika = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '930393262001638');
fbq('track', 'PageView');`;

export const metaMetrikaNoscript = {
  styles: { width: '1', height: '1', display: 'none' },
  alt: 'Meta',
  src: `https://www.facebook.com/tr?id=930393262001638&ev=PageView&noscript=1`,
};

export const googleMetrika = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'AW-11030905030');`;

export const googleMetrikaSrc = `https://www.googletagmanager.com/gtag/js?id=AW-11030905030`;
