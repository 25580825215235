const Modal = {
  baseStyle: {
    dialogContainer: {
      w: '100vw',
      h: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};

export default Modal;
