exports.components = {
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/AboutUs/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-confident-index-jsx": () => import("./../../../src/pages/Confident/index.jsx" /* webpackChunkName: "component---src-pages-confident-index-jsx" */),
  "component---src-pages-cookies-index-jsx": () => import("./../../../src/pages/Cookies/index.jsx" /* webpackChunkName: "component---src-pages-cookies-index-jsx" */),
  "component---src-pages-index-page-index-jsx": () => import("./../../../src/pages/IndexPage/index.jsx" /* webpackChunkName: "component---src-pages-index-page-index-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/Projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-ru-404-jsx": () => import("./../../../src/pages-ru/404.jsx" /* webpackChunkName: "component---src-pages-ru-404-jsx" */),
  "component---src-pages-ru-about-us-index-jsx": () => import("./../../../src/pages-ru/about-us/index.jsx" /* webpackChunkName: "component---src-pages-ru-about-us-index-jsx" */),
  "component---src-pages-ru-confidentiality-index-jsx": () => import("./../../../src/pages-ru/confidentiality/index.jsx" /* webpackChunkName: "component---src-pages-ru-confidentiality-index-jsx" */),
  "component---src-pages-ru-cookies-index-jsx": () => import("./../../../src/pages-ru/cookies/index.jsx" /* webpackChunkName: "component---src-pages-ru-cookies-index-jsx" */),
  "component---src-pages-ru-index-js": () => import("./../../../src/pages-ru/index.js" /* webpackChunkName: "component---src-pages-ru-index-js" */),
  "component---src-pages-ru-our-projects-index-jsx": () => import("./../../../src/pages-ru/our-projects/index.jsx" /* webpackChunkName: "component---src-pages-ru-our-projects-index-jsx" */)
}

