import React, { memo } from 'react';
import commonAssets from 'assets/common';
import { Image } from '@chakra-ui/react';

const Logo = ({ light = false, ...props }) => {
  return (
    <Image
      src={light ? commonAssets.logo.Aston.light : commonAssets.logo.Aston.dark}
      alt="Logo"
      {...props}
    />
  );
};

export default memo(Logo);
