export const links = [
  {
    href: `/about-us`,
    text: `About`,
  },
  {
    href: `/our-projects`,
    text: `Services`,
  },
];

export const variants = {
  open: {
    opacity: 1,
    visibility: 'visible',
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
      visibility: { delay: 0.02 },
    },
  },
  closed: {
    opacity: 0,
    visibility: 'hidden',
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      visibility: { delay: 0.25 },
    },
  },
};
