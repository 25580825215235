import { motion } from 'framer-motion';
import React, { memo } from 'react';
import { Text, Link as ChakraLink, Box } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

const NavItemMobile = ({ href, text, toggle = null, isBlank = false, ...props }) => {
  const { getPosition } = useGlobalAudioPlayer();
  const isHttp = href.startsWith('http');
  const linkHandler = e => {
    toggle && toggle();
    if (isBlank && getPosition() !== 0) {
      e.preventDefault();
      window.open(href, '_blank');
    }
  };
  return (
    <Box as={motion.div} {...props}>
      <ChakraLink
        as={isHttp ? 'a' : GatsbyLink}
        {...(isHttp ? { href } : { to: href })}
        w="full"
        textAlign="center"
        onClick={linkHandler}
      >
        <Text textStyle="button.header" whiteSpace="nowrap">
          {text}
        </Text>
      </ChakraLink>
    </Box>
  );
};
export default memo(NavItemMobile);
