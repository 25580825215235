import { scroller } from 'react-scroll';
import { ym } from './metrika';
export function goToForm() {
  ym('goto_orderform');
  scroller.scrollTo('myScrollToElement', {
    duration: 600,
    smooth: true,
    offset: -110,
  });
}
