import { Button, Text, VStack, Stack, Link as ChakraLink, Flex } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React, { memo, useEffect, useState } from 'react';
import { setCookie } from 'utils/cookie';
import { CONSENT_COOKIE_NAME, getCookieOptions } from 'define/cookies';
import Wrapper from 'shared/Wrapper';

const Cookies = () => {
  const [isVisible, setIsVisible] = useState(false);

  const acceptCookies = () => {
    setCookie(
      CONSENT_COOKIE_NAME,
      JSON.stringify({ ad_storage: true, analytics_storage: true }),
      getCookieOptions(),
    );
  };

  const onAccept = () => {
    if (isVisible) {
      localStorage.setItem('banner', 'seen');
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('banner')) {
      acceptCookies();
      setIsVisible(true);
    }
  }, []);
  return (
    isVisible && (
      <Flex
        w="100vw"
        zIndex={10}
        pos="fixed"
        bgColor="bg.white"
        bottom={0}
        left={0}
        py={{ laptop: '20px', tablet: '20px', mobile: '10px' }}
        align="center"
        justify="center"
        borderTop="1.6px solid"
        borderColor="text.black"
      >
        <Wrapper>
          <Stack
            w="full"
            direction={{ mobile: 'column', tablet: 'row' }}
            gap={{ laptop: '40px', tablet: '20px', mobile: '10px' }}
            justify="space-between"
            align="center"
          >
            <VStack spacing="8px" align="start" textAlign="start">
              <Text textStyle="h2">
                By continuing to use the website, you agree to our{' '}
                <ChakraLink as={GatsbyLink} to="/cookies" textDecor="underline">
                  Cookie Policy.
                </ChakraLink>
              </Text>
              <Text textStyle="h2">
                To learn more about how we process your personal data, read our{' '}
                <ChakraLink as={GatsbyLink} to="/confidentiality" textDecor="underline">
                  Privacy Policy
                </ChakraLink>
              </Text>
            </VStack>
            <Button
              onClick={onAccept}
              size="secondary"
              w={{ mobile: '100%', tablet: 'fit-content' }}
            >
              Accept
            </Button>
          </Stack>
        </Wrapper>
      </Flex>
    )
  );
};
export default memo(Cookies);
